import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { LoginProvider } from './contexts/LoginContext'
import { FileManagementProvider } from './contexts/FileManagementContext'
import { CodePanelProvider } from './contexts/CodePanelContext'
import { DebuggerProvider } from './contexts/DebuggerContext'
import { UserConfigProvider } from './contexts/UserConfigContext'
import { MissionEditorProvider } from './contexts/MissionEditorContext'
import { MissionProvider } from './contexts/MissionContext'
import { SceneProvider } from './contexts/SceneContext'
import { ContentDirectorProvider } from './contexts/ContentDirectorContext'
import { GroupsProvider } from './contexts/GroupsContext'
import { ContestsProvider } from './contexts/ContestContext'
import { NotificationsProvider } from './contexts/NotificationsContext'
import { OnlineStatusProvider } from './contexts/OnlineStatusContext'
import { EdataViewerProvider } from './contexts/EdataViewerContext'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { SnackbarProvider } from 'notistack'
import { parseURLForSharedFileId } from './content-manager/code-files/code-file-use-cases/parseURLForSharedFileId'
import { codeFileController } from './content-manager/code-files/code-file-controllers'

const notistackRef = React.createRef()

const handleSnackMouseEnter = (ev) => {
  // Bit of a hack, since notistack doesn't seem to provide a good way to do this.
  // We want to close snackbar on mouseEnter, so retrieve its key first so we can use notistack API to do so.
  const key = ev.target.getElementsByClassName('action-key-map')?.[0]?.id
  if (key) {
    notistackRef.current.closeSnackbar(Number.parseFloat(key))
  }
}

const handleSnackAction = (key) => {
  // Actions provide a unique key per snack instance, but no way to attach handlers - just to add children.
  // Store the unique key as ID of child element so we can find it from event handler (e.g. onMouseEnter).
  return (
    <div id={key.toString()} className='action-key-map'></div>
  )
}
const initApp = () => {
  const rootElt = document.getElementById('root')
  if (rootElt) {
    ReactDOM.render(
      <React.StrictMode>
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          onMouseEnter={handleSnackMouseEnter}
          action={handleSnackAction}
        >
          <OnlineStatusProvider>
            <GroupsProvider>
              <ContentDirectorProvider>
                <LoginProvider>
                  <FileManagementProvider>
                    <UserConfigProvider>
                      <NotificationsProvider>
                        <CodePanelProvider>
                          <SceneProvider>
                            <MissionProvider>
                              <DebuggerProvider>
                                <ContestsProvider>
                                  <MissionEditorProvider>
                                    <EdataViewerProvider>
                                      <App />
                                    </EdataViewerProvider>
                                  </MissionEditorProvider>
                                </ContestsProvider>
                              </DebuggerProvider>
                            </MissionProvider>
                          </SceneProvider>
                        </CodePanelProvider>
                      </NotificationsProvider>
                    </UserConfigProvider>
                  </FileManagementProvider>
                </LoginProvider>
              </ContentDirectorProvider>
            </GroupsProvider>
          </OnlineStatusProvider>
        </SnackbarProvider>
      </React.StrictMode>,
      rootElt
    )
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

const TAB_STATES = Object.freeze({
  LAUNCHING: 'launching',
  ACTIVE: 'active',
  DEAD: 'dead',
})

var tabState = TAB_STATES.LAUNCHING
const launchTs = Date.now()
const sharedFilesIds = []
function makeTabCommunicationObject({
  requestingTabState = false,
  sharedFileId = null,
  tabState,
  launchTs = null,
}) {
  return (
    Object.freeze({
      requestingTabState,
      sharedFileId,
      tabState,
      launchTs,
    })
  )
}

// If the user is using an old make.firialabs.com link, redirect them to makebit.
if (window.location.href.includes('/module/')) {
  window.location.href = `https://makebit.firialabs.com${window.location.href.slice(window.location.href.indexOf('/module/'))}`
}

const broadcastChannel = new window.BroadcastChannel('CodeSpNext_MultiTab')
broadcastChannel.onmessage = handleBroadcastReceived
function handleBroadcastReceived({ data }) {
  const msg = makeTabCommunicationObject({ ...data })
  if (msg.requestingTabState) {
    broadcastChannel.postMessage(makeTabCommunicationObject({ tabState, launchTs }))
    return
  }

  if (msg.sharedFileId) {
    if (tabState === TAB_STATES.LAUNCHING) {
      sharedFilesIds.push(msg.sharedFileId)
    }
    if (tabState === TAB_STATES.ACTIVE) {
      codeFileController.getSharedFile(msg.sharedFileId)
    }
  }

  if (msg.tabState === TAB_STATES.ACTIVE || (msg.tabState === TAB_STATES.LAUNCHING && msg.launchTs < launchTs)) {
    tabState = TAB_STATES.DEAD
    return
  }
}

async function establishTabState() {
  broadcastChannel.postMessage(makeTabCommunicationObject({ requestingTabState: true }))
  await new Promise(resolve => setTimeout(resolve, 50)) // WAIT 50 MS FOR OTHER TABS TO RESPOND
  if (tabState !== TAB_STATES.LAUNCHING) {
    return
  }
  tabState = TAB_STATES.ACTIVE
}

(async function () {
  await establishTabState()
  const sharedFileId = parseURLForSharedFileId()
  if (tabState === TAB_STATES.DEAD) {
    if (sharedFileId) {
      broadcastChannel.postMessage(makeTabCommunicationObject({ sharedFileId }))
      document.getElementById('detect-multitab-shared-file').style.display = 'block'
    }
    document.getElementById('browser-problem').style.display = 'block'
    document.getElementById('detect-csn-multitab').style.display = 'list-item'
  } else {
    if (sharedFileId) {
      sharedFilesIds.push(sharedFileId)
    }
    sharedFilesIds.map(codeFileController.getSharedFile)
    initApp()
  }
})()