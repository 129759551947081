import React from 'react'

const EdataViewerContext = React.createContext()
export const EdataViewerProvider = ({ children }) => {
  const [visible, setVisible] = React.useState(false)
  const [dataViewerEmpty, setDataViewerEmpty] = React.useState(false)
  const [notificationVisible, setNotificationVisible] = React.useState(false)
  const [progressNotificationVisible, setProgressNotificationVisible] = React.useState(false)

  const setEdataViewerDataEmpty = React.useCallback((empty) => {
    setDataViewerEmpty(empty)
  }, [])

  const setEdataViewerVisible = React.useCallback((visible) => {
    setNotificationVisible(false)
    setVisible(visible)
  }, [])

  const clearEdataViewer = React.useRef(() => {})
  const setClearViewerCb = React.useCallback((cb) => {
    const clear = () => {
      setProgressNotificationVisible(false)
      cb()
    }
    clearEdataViewer.current = clear
  }, [])

  const showNotification = React.useCallback(() => {
    if (visible) {
      return
    }
    setNotificationVisible(true)
  }, [visible])

  const clearNotification = React.useCallback(() => {
    setNotificationVisible(false)
  }, [])

  const showProgressNotification = React.useCallback(() => {
    setProgressNotificationVisible(true)
  }, [])

  const clearProgressNotification = React.useCallback(() => {
    setProgressNotificationVisible(false)
  }, [])


  return (
    <EdataViewerContext.Provider
      value={{
        visible,
        setEdataViewerVisible,
        clearEdataViewer: clearEdataViewer.current,
        setClearViewerCb,
        dataViewerEmpty,
        setEdataViewerDataEmpty,
        showNotification,
        clearNotification,
        showProgressNotification,
        clearProgressNotification,
        notificationVisible,
        progressNotificationVisible,
      }}>
      {children}
    </EdataViewerContext.Provider>
  )
}

export const useEdataViewer = () => {
  return React.useContext(EdataViewerContext)
}