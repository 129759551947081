/* Embedded Data Viewer - Show data received from connected device via USB "side channel"
   Initial use-case is images from CodeAIR. Text messages are also supported.

   Hope to add strip-chart object in the future. For that, rather than always creating a new chart, a ChartID
   could be specified. If the ChartID already exists, the new plot data will be appended.
*/
import React, { Fragment, useEffect, useRef } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts'
import { crc32_le } from './utils/crc'
import FileDownloadIcon from '@material-ui/icons/GetApp'
import { Button } from '@material-ui/core'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import { genContrastColor, genShadeColor } from './utils/shade-highlight-tools'
import { useUserConfig } from './contexts/UserConfigContext'
import { CircularProgress } from '@material-ui/core'
import { useEdataViewer } from './contexts/EdataViewerContext'

function ImagePlaceHolder({ style, transferFailed = false }) {
  useUserConfig()
  const [timeoutReached, setTimeoutReached] = React.useState(false)
  React.useEffect(() => {
    const to = setTimeout(() => {
      setTimeoutReached(true)
    }, 20 * 1000)
    return () => {
      clearTimeout(to)
    }
  }, [])

  const failed = transferFailed || timeoutReached
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        ...style,
      }}
    >
      <div style={{
        width: 162,
        height: 122,
        backgroundColor: genShadeColor(0.5, 0.1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        gap: 10,
      }}
      >
        {failed ? 'Image failed to load!' : <CircularProgress style={{ color: genContrastColor(1) }} />}
        {!failed && <div>Image Loading</div>}
      </div>
    </div>
  )
}

function ImageRenderer({ buf, width, height, style }) {
  const canvasRef = useRef(null)
  const imageDataRef = useRef(null)

  useEffect(() => {
    if (buf && width && height && canvasRef.current) {
      const canvas = canvasRef.current
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')

      // Create a Uint8ClampedArray to hold RGBA values
      const imageDataArray = new Uint8ClampedArray(width * height * 4)

      let bufIndex = 0
      for (let i = 0; i < width * height; i++) {
        // Combine two bytes into one 16-bit value
        const highByte = buf[bufIndex++]
        const lowByte = buf[bufIndex++]
        const rgb565 = (highByte << 8) | lowByte

        // Convert RGB565 to RGB888
        const r = ((rgb565 >> 11) & 0x1F) * 0xFF / 0x1F
        const g = ((rgb565 >> 5) & 0x3F) * 0xFF / 0x3F
        const b = (rgb565 & 0x1F) * 0xFF / 0x1F

        // Set RGBA values
        const imageDataIndex = i * 4
        imageDataArray[imageDataIndex] = r
        imageDataArray[imageDataIndex + 1] = g
        imageDataArray[imageDataIndex + 2] = b
        imageDataArray[imageDataIndex + 3] = 255 // Fully opaque
      }

      // Create ImageData from the array
      const imageData = new ImageData(imageDataArray, width, height)
      imageDataRef.current = imageData

      // Render the image on the canvas
      ctx.putImageData(imageData, 0, 0)
    }
  }, [buf, width, height])

  useEffect(() => {
    // Hook visibility change to ensure image is restored if context has been
    // cleared (sometimes happens after idle periods, leaving images blank).
    const handleVisibilityChange = () => {
      if (!document.hidden && canvasRef.current && imageDataRef.current) {
        const ctx = canvasRef.current.getContext('2d')
        ctx.putImageData(imageDataRef.current, 0, 0)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('pageshow', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('pageshow', handleVisibilityChange)
    }
  }, [])


  const handleDownload = () => {
    const canvas = canvasRef.current
    if (canvas) {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const link = document.createElement('a')
            link.download = 'image.png'
            link.href = URL.createObjectURL(blob)
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            console.error('Failed to create Blob from canvas')
          }
        },
        'image/png',
        1.0 // Image quality (for image/jpeg), ignored for PNG
      )
    }
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block', ...style }}>
      <canvas ref={canvasRef} style={{ border: '1px solid black' }}></canvas>
      <FileDownloadIcon
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          padding: '4px',
          cursor: 'pointer',
        }}
        onClick={() => handleDownload()}
      />
    </div>
  )
}

function mergeSortByTs(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('Input must be an array.')
  }

  if (arr.length <= 1) {
    return arr
  }

  const mid = Math.floor(arr.length / 2)
  const left = arr.slice(0, mid)
  const right = arr.slice(mid)

  const sortedLeft = mergeSortByTs(left)
  const sortedRight = mergeSortByTs(right)

  return merge(sortedLeft, sortedRight)
}

function merge(left, right) {
  const merged = []
  let i = 0
  let j = 0

  while (i < left.length && j < right.length) {
    if (left[i].ts < right[j].ts) {
      merged.push(left[i])
      i++
    } else if (left[i].ts === right[j].ts) {
      merged.push({ ...left[i], ...right[j] }) // Merge if ts is the same
      i++
      j++
    } else {
      merged.push(right[j])
      j++
    }
  }

  // Add any remaining elements from left or right
  return merged.concat(left.slice(i)).concat(right.slice(j))
}


const darkModeGraphColors = [
  '#00FFFF',
  '#FFD700',
  '#ADFF2F',
  '#FF69B4',
  '#FFA500',
  '#90EE90',
  '#00BFFF',
  '#FFFF00',
  '#00FF00',
  '#1E90FF',
  '#FFC0CB',
  '#7B68EE',
  '#FFA07A',
  '#40E0D0',
  '#32CD32',
  '#F08080',
  '#DA70D6',
]

const lightModeGraphColors = [
  '#00008B',
  '#8B0000',
  '#006400',
  '#4B0082',
  '#2F4F4F',
  '#A0522D',
  '#DC143C',
  '#8A2BE2',
  '#191970',
  '#8B008B',
  '#A52A2A',
  '#008080',
  '#0000CD',
  '#9932CC',
  '#800080',
  '#008000',
  '#8B4513',
]

function isFloat(n) {
  return !Number.isInteger(n)
}

function XAxisTick({ x, y, payload, ticks, axisLength, xTickCount, msGranularity, detailColor }){
  const index = ticks.indexOf(payload.value)
  const tsTick = index === xTickCount

  const formatValue = () => {
    if (tsTick) {
      return `${payload.value}ms`
    }

    const xAxisLength = axisLength[0]
    if (xAxisLength < 425) {
      if (index !== 0 && index !== 5 && index !== xTickCount) {
        return ''
      }
    } else if (xAxisLength < 1200) {
      if (index % 2 !== 0) {
        return ''
      }
    }

    const diffFromIndex = xTickCount - index
    const distanceFromIndex = Math.abs(diffFromIndex)
    const secondsFromIndex = distanceFromIndex * msGranularity / 1000

    return `${diffFromIndex > 0 ? '-':'+'}${secondsFromIndex}s`
  }

  const value = formatValue()
  let excessValues = 0
  if (tsTick && value.length > 8 && ticks.length-1 === index) {
    excessValues = (value.length - 8)
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={`-${excessValues/3}em`}
        y={0}
        dy={16}
        textAnchor='middle'
        fill={detailColor}
        style={{paddingRight: 10}}
      >
        {value}
      </text>
    </g>
  )
};

function seededRand(seed, range) {
  function simpleHash(n) {
    n = (n ^ 61) ^ (n >>> 16)
    n = n + (n << 3)
    n = n ^ (n >>> 4)
    n = n * 0x27d4eb2d
    n = n ^ (n >>> 15)
    return n >>> 0 // Ensure positive integer
  }

  let hashed = simpleHash(seed + range[0] + range[1])
  return (hashed % (range[1] - range[0] + 1)) + range[0]
}

function getVertDivisionSize(min, max, numDivs) {
  const range = max - min
  const roughStep = range / numDivs
  const absStep = Math.abs(roughStep)
  const exponent = Math.floor(Math.log10(absStep))
  const fraction = absStep / Math.pow(10, exponent)

  let niceFraction = 10
  if (fraction <= 1) {
    niceFraction = 1
  } else if (fraction <= 2) {
    niceFraction = 2
  } else if (fraction <= 2.5) {
    niceFraction = 2.5
  } else if (fraction <= 5) {
    niceFraction = 5
  }

  return niceFraction * Math.pow(10, exponent)
}

function getGridData(min, max, numDivs) {
  const divisionSize = getVertDivisionSize(min, max, numDivs)
  const gridStart = Math.floor(min / divisionSize) * divisionSize
  const gridEnd = Math.ceil(max / divisionSize) * divisionSize

  // Automatically determine decimal places
  const decimalPlaces = Math.max(0, -Math.floor(Math.log10(divisionSize))) + 3
  const factor = Math.pow(10, decimalPlaces)

  const gridLines = []
  for (let y = gridStart; y <= gridEnd; y += divisionSize) {
    gridLines.push(Math.round(y * factor) / factor) // Fix floating-point errors dynamically
  }

  const divisionsBelowZero = gridLines.filter(val => val < 0).length

  return {
    divisionSize,
    gridStart,
    gridEnd,
    gridLines,
    divisionsBelowZero,
  }
}

function seededRandom(seed) {
  let m_w = seed
  let m_z = 987654321
  const mask = 0xffffffff

  return function() {
    m_z = (36969 * (m_z & 65535) + (m_z >> 16)) & mask
    m_w = (18000 * (m_w & 65535) + (m_w >> 16)) & mask
    let result = ((m_z << 16) + m_w) & mask
    // Ensure positive result and normalize to [0, 1)
    return (result >>> 0) / 4294967296
  }
}

function seededShuffle(array, seed) {
  const random = seededRandom(seed)
  const shuffledArray = [...array]

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }

  return shuffledArray
}

function stringToNumber(inputString) {
  let number = 0
  for (let i = 0; i < inputString.length; i++) {
    number += inputString.charCodeAt(i)
  }
  return number
}

function ChartRenderer({ chartName, chartType, initialValues, setDataChangeCb }) {
  const idx = stringToNumber(chartName)
  const rawData = React.useRef({})
  const [chartData, setChartData] = React.useState([])
  const lineKeys = React.useRef([])
  const [{ lightTheme }] = useUserConfig()
  const [msGranularity] = React.useState(500)
  const [chartSize, setChartSize] = React.useState([1, 1])
  const { visible: edataViewerVisible } = useEdataViewer()

  const chartRef = React.useRef(null)
  const executeScroll = () => {
    chartRef.current.scrollIntoView()
  }

  const colorIndexes = React.useRef([])
  React.useEffect(() => {
    const seed = seededRand(idx, [0, 100000])
    colorIndexes.current = seededShuffle(darkModeGraphColors.map((_,idx) => idx), seed)
  }, [idx])

  React.useEffect(() => {
    function handleChartDataChange(seriesName, values) {
      if (!rawData.current[seriesName]) {
        rawData.current[seriesName] = []
      }
      rawData.current[seriesName].push(...values)

      const data = []
      lineKeys.current = []
      Object.entries(rawData.current).forEach(([chartSeries, values]) => {
        let chartSeriesName = chartSeries
        if (chartSeries === '') {
          chartSeriesName = 'sequence_1'
        }
        lineKeys.current.push(chartSeriesName)
        const formattedSeriesValues = values.map(([v, i]) => {
          let val = v
          if (isFloat(val)) {
            val = val.toFixed(3)
          }
          return {ts: i, [chartSeriesName]: val}
        })
        data.push(...formattedSeriesValues)
      })

      lineKeys.current = [...new Set(lineKeys.current)]
      setChartData(mergeSortByTs(data))
    }

    handleChartDataChange(initialValues.seriesName, initialValues.values)
    setDataChangeCb(handleChartDataChange)
  }, [chartName, initialValues.seriesName, initialValues.values, setDataChangeCb])

  const xTickCount = 10
  const [yAxisTickCount, setYAxisTickCount] = React.useState(1)
  const [axisLength, setAxisLength] = React.useState(0)
  function handleResize(x, y) {
    // attained by comparing the x&y to the actual values in the browser
    const xCorrection = 94
    const yCorrection = 64

    const xAxisLength = x - xCorrection
    const yAxisLength = y - yCorrection

    const xAxisTickLength = xAxisLength / xTickCount
    let yTickCount = Math.round(yAxisLength/xAxisTickLength)
    if (yTickCount < 2 && yTickCount > 0.5) {
      yTickCount = 2
    }
    setYAxisTickCount(yTickCount + 1)
    setAxisLength([xAxisLength, yAxisLength])
    if (x !== 0 || y !== 0) {
      setChartSize([x, y])
    }
  }

  const [domain, setDomain] = React.useState([])
  const [xTicks, setXTicks] = React.useState([])
  React.useEffect(() => {
    let domain = []
    if (chartData.length === 1) {
      domain = [chartData[0].ts, chartData[0].ts + (xTickCount * msGranularity)]
    } else if (chartData.length > 1) {
      if ((chartData[chartData.length - 1].ts - chartData[0].ts) < (xTickCount * msGranularity)) {
        domain = [chartData[0].ts, chartData[0].ts + (xTickCount * msGranularity)]
      } else {
        domain = [chartData[chartData.length - 1].ts - (xTickCount * msGranularity), chartData[chartData.length - 1].ts]
      }
    }
    if (domain.length > 0 && domain[0] === domain[1]) {
      domain = []
    }

    let xTicks = []
    if (domain.length > 0) {
      let ts = domain[0]
      while (ts < domain[1]) {
        xTicks.push(ts)
        ts += msGranularity
      }
      xTicks.push(domain[1])
    }

    setXTicks(xTicks)
    setDomain(domain)
  }, [chartData, msGranularity])


  function findDivisibleGreater(num1, num2) {
    const nextMultiple = Math.floor(num1 / num2) + 1
    return nextMultiple * num2
  }

  const [range, setRange] = React.useState([])
  const [yTicks, setYTicks] = React.useState([])
  React.useEffect(() => {
    let numbers = chartData.map((d) => {
      let keys = Object.keys(d)
      let vals = []
      for (let key of keys) {
        if (key !== 'ts') {
          vals.push(d[key])
        }
      }
      return vals
    }).flat()
    let max = Math.max(...numbers)
    let min = Math.min(...numbers)

    const {
      gridStart,
      gridEnd,
      gridLines,
    } = getGridData(min, max, yAxisTickCount)

    if (numbers.length > 1) {
      max = findDivisibleGreater(max - min, yAxisTickCount) + min
    }

    let range = [min, max]
    let yTicks = []
    if (range.length > 0) {
      if (range[0] === range[1]) {
        yTicks = [range[0]]
      } else {
        range = [gridStart, gridEnd]
        yTicks = gridLines
      }
    }

    setRange(range)
    setYTicks(yTicks)
  }, [chartData, yAxisTickCount])

  const detailColor = genContrastColor(0.7, 0.8)
  const lineColors = lightTheme ? lightModeGraphColors : darkModeGraphColors
  const lineSeed = seededRand(idx, [0, lineColors.length - 1])
  return (
    <div
      style={{
        width: 'calc(100%)',
        height: 'calc(100% + 0.5em)',
        paddingTop: 2,
        paddingBottom: 2,
        marginBottom: 5,
        marginTop: 5,
      }}
      key={chartName}
    >
      <div
        style={{
          position: 'relative',
          bottom: -4,
          left: 4,
          fontSize: 16,
          zIndex: 10,
        }}
      >
        <Button style={{textTransform: 'none', height: 26}} onClick={executeScroll}>
          <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
            <OpenWithIcon style={{fontSize: 16}} />
            {chartName}
          </div>
        </Button>
      </div>
      <div
        style={{
          padding: 3,
          border: `1px solid ${genContrastColor(0.1, 0.3)}`,
          backgroundColor: 'rgba(255,255,255,0.02)',
          borderRadius: 5,
          marginTop: -26,
          width: '100%',
          height: '100%',
        }}>
        <div ref={chartRef} style={{position: 'relative', top: -10}} />
        {!edataViewerVisible ?
          <div style={{width: chartSize[0], height: chartSize[1]}} />:
          <ResponsiveContainer width={'100%'} height={'100%'} onResize={handleResize} debounce={5}>
            <LineChart
              width={400}
              height={200}
              style={{
                minHeight: 1,
                minWidth: 1,
              }}
              data={chartData}
              margin={{
                top: 15,
                right: 34,
                left: 0,
                bottom: 15,
              }}
              animation={false}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                tick={<XAxisTick {...{ticks: xTicks, axisLength, xTickCount, msGranularity, detailColor }} />}
                dataKey='ts'
                stroke={detailColor}
                scale='linear'
                allowDataOverflow={true}
                domain={domain}
                ticks={xTicks}
                type='number'
                interval={0}
              >
                <Label value='Time' position='bottom' offset={-4} fill={detailColor}/>
              </XAxis>
              <YAxis stroke={detailColor} domain={range} ticks={yTicks} interval={0} />
              <Tooltip
                contentStyle={{
                  backgroundColor: lightTheme ? 'white' : '#343434',
                  color: detailColor,
                }}
              />
              <Legend align='center' verticalAlign='top' width={'calc(100% - 60px)'} wrapperStyle={{marginLeft: 60, marginTop: -10}}/>
              {lineKeys.current.map((key, idx) =>
                <Line
                  isAnimationActive={false}
                  type='monotone'
                  dataKey={key}
                  stroke={lineColors[colorIndexes.current[(idx + lineSeed) % darkModeGraphColors.length]]}
                  activeDot={{ r: 8 }}
                  dot={false}
                  key={key}
                  connectNulls
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        }
      </div>
    </div>
  )
}

function decodeChartDataFromCharString(charString) {
  // Convert the char string back to a Uint8Array
  const buffer = new Uint8Array(charString.split('').map(char => char.charCodeAt(0)))

  // Read the descriptor (16 bytes)
  const descriptor = new TextDecoder().decode(buffer.slice(0, 16))
  const chartTypeCode = descriptor.charCodeAt(5) // 6th byte is the chart type
  const chartType = chartTypeCode === 0 ? 'line' : 'other'

  // Extract chart name (10 characters, padded with null bytes)
  // eslint-disable-next-line no-control-regex
  const chartName = descriptor.slice(6, 16).replace(/\x00/g, '')

  // Extract the series name (null-terminated string)
  let seriesName = ''
  let seriesEndIndex = 16
  while (seriesEndIndex < buffer.length && buffer[seriesEndIndex] !== 0x00) {
    seriesEndIndex++
  }
  if (seriesEndIndex > 16) {
    seriesName = new TextDecoder().decode(buffer.slice(16, seriesEndIndex))
  }

  // Extract data (8 bytes per point: 4 for float, 4 for timestamp)
  const dataStartIndex = seriesEndIndex + 1
  const data = []
  for (let i = dataStartIndex; i < buffer.length; i += 8) {
    const view = new DataView(buffer.buffer, i, 8)
    const value = view.getFloat32(0, false) // Big-endian float
    const timestamp = view.getUint32(4, false) // Big-endian timestamp
    data.push([value, timestamp])
  }

  // Return the decoded information
  return {
    chartName,
    chartType,
    seriesName,
    values: data,
  }
}

export const EdataViewer = ({ edata }) => {
  const [contentList, setContentList] = React.useState([])
  const chartDataUpdatedCbs = React.useRef({})
  const { setClearViewerCb, setEdataViewerDataEmpty, showNotification, showProgressNotification, clearProgressNotification } = useEdataViewer()

  React.useEffect(() => {
    setClearViewerCb(() => {
      chartDataUpdatedCbs.current = {}
      setContentList([])
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setEdataViewerDataEmpty(contentList.length === 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList])

  function setChartLastUpdatedCb(chartName, cb) {
    chartDataUpdatedCbs.current[chartName] = cb
  }

  const progressNotificationCb = React.useRef(null)
  React.useEffect(() => {
    return () => clearTimeout(progressNotificationCb.current)
  }, [])

  const lastDescriptor = React.useRef(null)
  const [lastParsedEndOfMessageReached, setLastParsedEndOfMessageReached] = React.useState(null)
  const eomStatusChanged = lastParsedEndOfMessageReached !== edata.endOfMessageReached
  React.useEffect(() => {
    if (eomStatusChanged) {
      setLastParsedEndOfMessageReached(edata.endOfMessageReached)
      const data = edata.data
      let content
      let contentChanged = true

      const rcvError = (data === null && edata.endOfMessageReached)
      if (rcvError) {
        console.log('Edata receive error! TODO: indicate to user')
        clearTimeout(progressNotificationCb.current)
        clearProgressNotification()
        if (!lastDescriptor.current) {
          return
        }

        const ds = lastDescriptor.current
        if (ds.startsWith('image')) {
          // Replace the placeholder with a failed image
          setContentList(x => [
            ...x.slice(0, -1),
            <ImagePlaceHolder style={{margin:'0.25em'}} transferFailed={true}/>,
          ])
        }

        return
      }

      // First 16 bytes of edata is the type descriptor.
      const td = data.substring(0, 16)
      lastDescriptor.current = td

      if (edata.endOfMessageReached) {
        clearTimeout(progressNotificationCb.current)
        clearProgressNotification()

        if (td.startsWith('text')) {
          // Nothing else in descriptor. Just grab text from the data payload.
          // Force text to wrap flexbox
          content = (<span style={{flexBasis:'100%'}}>{data.substring(16)}</span>)
        } else if (td.startsWith('image')) {
          // descriptor="imageBWWHH" | B=bits per pixel, WW=width16, HH=height16
          // const bpp = td.charCodeAt(5)
          const width = (td.charCodeAt(6) << 8) | td.charCodeAt(7)
          const height = (td.charCodeAt(8) << 8) | td.charCodeAt(9)
          const imgBuf = Uint8Array.from(data.substring(16), c => c.charCodeAt(0))

          const crc = crc32_le(imgBuf)
          console.log(`Image ${width}x${height} received, crc=${crc.toString(16).padStart(8, '0')}`)

          // Replace the placeholder with the actual image
          setContentList(x => [
            ...x.slice(0, -1),
            <ImageRenderer buf={imgBuf} width={width} height={height} style={{margin:'0.25em'}}/>,
          ])
        } else if (td.startsWith('chart')) {
          const { chartName, seriesName, values, chartType } = decodeChartDataFromCharString(data)
          // If this is the first time we've seen the chart id
          if (!chartDataUpdatedCbs.current[chartName]) {
            chartDataUpdatedCbs.current[chartName] = () => {}
            content = <ChartRenderer idx={Object.keys(chartDataUpdatedCbs.current).length} chartName={chartName} chartType={chartType} initialValues={{seriesName, values}} setDataChangeCb={cb => setChartLastUpdatedCb(chartName, cb)} />
          } else {
            contentChanged = false
          }

          chartDataUpdatedCbs.current[chartName](seriesName, values)
        } else {
          content = (<span>Unknown embedded data type</span>)
        }
      }

      if (!edata.endOfMessageReached){
        progressNotificationCb.current = setTimeout(() => {
          showProgressNotification()
        }, 500)

        if (td.startsWith('image')) {
          contentChanged = false
          // We know this will be triggered, might as well start it now
          showProgressNotification()
          content = (
            <ImagePlaceHolder style={{margin:'0.25em'}}/>
          )
        }
      }

      if (contentChanged && edata.endOfMessageReached) {
        showNotification()
      }

      // Append content
      if (content) {
        setContentList(x => [...x, content])
      }
    }
  }, [clearProgressNotification, edata.data, edata.endOfMessageReached, eomStatusChanged, showNotification, showProgressNotification])

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        height: '100%',
        border: 'thin solid gray',
        padding: '0.5em',
        overflow: 'auto',
      }}>
      {contentList.map((item, index) => (
        <Fragment key={index}>
          {item}
        </Fragment>
      ))}
    </div>
  )
}
